import { Component, Input, OnInit } from '@angular/core';
import { PopoverController, ToastController } from '@ionic/angular';
import { Ticket } from 'src/app/models/ticket';
import { AuthService } from 'src/services/auth.service';
import { TicketService } from 'src/services/ticket.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-finish-ticket',
  templateUrl: './finish-ticket.component.html',
  styleUrls: ['./finish-ticket.component.scss'],
})
export class FinishTicketComponent implements OnInit {
  @Input() ticket: Ticket;
  userCredits: number;
  value: any;
  constructor(
    public toastController: ToastController,
    public popoverController: PopoverController,
    public ticketService: TicketService,
    public userService: UserService
  ) {}

  ngOnInit() {
    this.userCredits = this.userService.getUserCredits();
  }

  segmentChange(event: any) {
    this.value = event.target.value;
  }
  async presentToastSuccess() {
    //console.log('FINISH TICKET', ticket_id);

    const toast = await this.toastController.create({
      message: 'Потврдата е успешна!',
      duration: 2000,
    });

    this.ticketService.updateFinishTicket(this.ticket);
    setTimeout(() => {console.log("this is the third message")}, 500);
    this.userService.updateUserCredits(this.ticket.creditsEarn);
    setTimeout(() => {console.log("this is the third message")}, 500);
    this.userService.consumeUserCredits(this.ticket.creditsPrice, this.value);
    toast.present();
    this.dismissPopover();
  }

  async dismissPopover(showAddressModal = false) {
    await this.popoverController.dismiss({ showAddress: showAddressModal });
  }
}
