import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthData } from '../app/models/auth-data';
import { Router } from '@angular/router';
import { ExternalHost } from './variables';

//import { User } from '../interfaces/user.model';
//import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  //private loacalHost: string = 'http://localhost:3000/api/auth';
  private externalHost: string = ExternalHost;
  private token: string;
  private isAuth = false;
  private tokenTimer: any;
  private userId: string;
  private userName: string;
  private userAdminStatus: number; //????
  private authStatusListenerSub = Subscription;
  private authStatusListener = new Subject<boolean>();
  categories: AuthData[] = [];
  categoriesUpdated = new Subject<AuthData[]>();

  constructor(private http: HttpClient, private router: Router) {}

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuth;
  }

  getUserId() {
    return this.userId;
  }
  getUserName() {
    return this.userName;
  }

  getUserAdminStatus() {
    return this.userAdminStatus;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  signupUser(
    authPhone: number,
    authPassword: string,
    authAdminStatus: number,
    authName: string
  ): void {
    const authData: AuthData = {
      phone: authPhone,
      password: authPassword,
      admin_status: authAdminStatus,
      name: authName,
    };
    this.http
      .post(this.externalHost + '/auth/signup', authData)
      .subscribe((responseData) => {
        //this.router.navigate(['/home']);
        console.log(responseData);
        this.loginUser(authData.phone, authData.password);
      });
  }

  loginUser(authNumber: number, authPassword: string): void {
    const authData: AuthData = {
      phone: authNumber,
      password: authPassword,
      //admin?
      admin_status: null,
      name: null,
    };
    this.http
      .post<{
        name: string;
        token: string;
        expiresIn: number;
        userId: string;
        userAdminStatus: number;
      }>(this.externalHost + '/auth/login', authData)
      .subscribe((response) => {
        const token = response.token;
        this.token = token;
        if (token) {
          const expiresInDuration = response.expiresIn;
          this.setAuthTimer(expiresInDuration);
          this.isAuth = true;
          this.authStatusListener.next(true);
          this.userId = response.userId;
          const userId = response.userId;
          this.userName = response.name;
          const userName = response.name;

          this.userAdminStatus = response.userAdminStatus;
          const userAdminStatus = response.userAdminStatus;

          const now = new Date();
          //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! vrvit -number
          const expirationDate = new Date(
            now.getTime() + expiresInDuration * 1000
          );
          this.saveAuthData(
            token,
            expirationDate,
            userId,
            userAdminStatus,
            userName
          );
          //console.log(expirationDate);
          this.router.navigate(['/home']);
        }
      });
  }

  logOut() {
    this.token = null;
    this.isAuth = false;
    this.authStatusListener.next(false);
    //console.log(this.getAuthStatusListener(), 'aouto auth auth');
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.userId = null;
    this.router.navigate(['/authorisation']);
  }

  autoAuthUser() {
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    //console.log(authInformation, expiresIn);
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuth = true;
      this.userId = authInformation.userId;
      this.userAdminStatus = authInformation.userAdminStatus;
      this.userName = authInformation.userName;

      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);
      // console.log(this.getAuthStatusListener(), 'aouto auth auth');
    }
  }

  private setAuthTimer(duration: number) {
    //console.log('setting timer' + duration);
    this.tokenTimer = setTimeout(() => {
      this.logOut();
    }, duration * 1000);
  }

  private saveAuthData(
    token: string,
    expirationDate: Date,
    userId: string,
    userAdminStatus: number,
    userName: string
  ) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', userId);
    localStorage.setItem('userAdminStatus', userAdminStatus.toString());
    localStorage.setItem('userName', userName.toString());
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
    localStorage.removeItem('userAdminStatus');
    localStorage.removeItem('userName');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    const userId = localStorage.getItem('userId');
    const userAdminStatus = localStorage.getItem('userAdminStatus');
    const userName = localStorage.getItem('userName');
    if (!token || !expirationDate || !userId || !userAdminStatus || !userName) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      userAdminStatus: Number(userAdminStatus),
      userName: userName,
    };
  }
}
