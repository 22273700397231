import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddProductComponent } from './popovers/add-product/add-product.component';
import { AddServiceComponent } from './popovers/add-service/add-service.component';
import { PurchaseProductComponent } from './popovers/purchase-product/purchase-product.component';
import { PurchaseServiceComponent } from './popovers/purchase-service/purchase-service.component';
import { CancelTicketComponent } from './popovers/cancel-ticket/cancel-ticket.component';
import { ProductsComponent } from './home/products/products.component';
import { AuthInterceptor } from './interceptors/auth-interceptor';
import { ChatComponent } from './home/chat/chat.component';
import { FinishTicketComponent } from './popovers/finish-ticket/finish-ticket.component';
import { EditUserComponent } from './popovers/edit-user/edit-user.component';
import { AddAdminComponent } from './popovers/add-admin/add-admin.component';
//import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

@NgModule({
  declarations: [
    AppComponent,
    AddProductComponent,
    AddAdminComponent,
    AddServiceComponent,
    PurchaseProductComponent,
    PurchaseServiceComponent,
    FinishTicketComponent,
    CancelTicketComponent,
    EditUserComponent,
  ],
  entryComponents: [],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,

      //provide: RouteReuseStrategy,
      // useClass: IonicRouteStrategy,
    },
    //CallNumber,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
