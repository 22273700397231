import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user';
import { AddAdminComponent } from 'src/app/popovers/add-admin/add-admin.component';
import { AuthService } from 'src/services/auth.service';
import { UserService } from 'src/services/user.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  adminUsers: User[] = [];
  adminSub: Subscription;
  user: User = <User>{};
  userSub: Subscription;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public popoverController: PopoverController
  ) {}

  ngOnInit() {
    this.authService.autoAuthUser();
    this.userService.getAdminUsers();

    this.userService.getUserById(this.authService.getUserId());

    this.userSub = this.userService
      .getUserUpdateListener()
      .subscribe((user: User) => {
        this.user.name = user.name;
        this.user.phone = user.phone;
        this.user.theme = user.theme;
        if (this.user.theme != false) {
          document.body.setAttribute('color-theme', 'dark');
        } else {
          document.body.setAttribute('color-theme', 'light');
        }
      });

    this.adminSub = this.userService
      .getAdminUsersUpdateListener()
      .subscribe((adminUsers: User[]) => {
        this.adminUsers = adminUsers;
        console.log('tie se', this.adminUsers);
      });
  }
  segmentChange(event: Event) {}

  async addAdmin() {
    const popover = await this.popoverController.create({
      component: AddAdminComponent,
      componentProps: {},
      cssClass: 'my-custom-class',
      translucent: true,
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }

  removeAdmin(id: string) {
    this.userService.removeAdmin(id);
  }
}
